<template>
  <div class="card contact-card">
    <div class="card-content">
      <div class="contact-card__avatar">
        <div
          :style="{backgroundImage: `url(${contact.image.cropped})`}"
          class="contact-card__avatar-inner"
        />
      </div>
      <div class="contact-card__title has-text-weight-semibold">
        {{ contact.name }}
      </div>
      <div class="contact-card__job-desc has-text-muted is-size-7">
        {{ contact.job_description }}
      </div>
    </div>
    <div class="card-footer has-padding is-block is-small">
      <div class="contact-card__contacts-title has-text-weight-semibold is-uppercase">
        Contact
      </div>
      <div class="contact-card__contacts">
        <div
          v-if="contact.email"
          class="contact-card__contact contact-card__contact-email"
        >
          <i class="contact-card__icon hf hf-fw hf-lg hf-envelope has-text-primary" />
          <a
            :href="'mailto:' + contact.email"
            :title="contact.email"
            class="has-ellipsis is-block"
          >
            {{ contact.email }}
          </a>
        </div>
        <div
          v-if="contact.phone"
          class="contact-card__contact contact-card__contact-phone"
        >
          <i class="contact-card__icon hf hf-fw hf-lg hf-phone has-text-primary" />
          <a
            :href="'mailto:' + contact.phone"
            :title="contact.phone"
          >
            {{ contact.phone }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    contact: {
      type: Object,
      required: true
    }
  }
}
</script>
<style lang="scss">
@import "~utils";

.contact-card {
  width: 100%;

  &__avatar {
    &-inner {
      background: transparent center/cover no-repeat;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      margin: auto;
      border: 1px solid $hf__input-border-color;
    }

    margin-bottom: 1rem;
  }

  &__job-desc {
    min-height: calc(0.8em * 1.8);
  }

  &__contacts {
    font-size: $size-6;

    .contact-card__contact {
      line-height: 1.8;
      display: flex;
      align-items: center;

      .contact-card__icon {
        margin-right: $margin-small;
      }
    }
  }
}
</style>
