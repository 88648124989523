<template>
  <div class="StoryFull EmbeddedStory">
    <div class="container">
      <article
        v-loading="fetchingStory"
        class="StoryFull__wrapper"
        itemtype="http://schema.org/NewsArticle"
        itemscope
      >
        <section
          v-if="story"
          class="text-block"
        >
          <div class="m-v-s">
            <router-link
              v-if="!storySettings.hideBackButton"
              :to="backRouteConfig"
              data-testid="EmbeddedStory_BackLink"
            >
              <i class="el-icon-arrow-left" />{{ $t('pages.embedded_story.back_link') }}
            </router-link>
          </div>

          <div
            v-if="!storySettings.storyImage && storyCroppedCover"
            class="columns is-gapless is-marginless"
            data-testid="EmbeddedStory_Cover"
          >
            <div class="column">
              <div
                :style="{backgroundImage: `url(${storyCroppedCover})`}"
                class="StoryFull__cover responsive-embed widescreen"
              />
            </div>
          </div>

          <div class="columns">
            <div class="column StoryFull__content">
              <div class="StoryFull__content-inner">
                <div class="StoryFull__meta m-b-l">
                  <div
                    v-if="!storySettings.storyTypeTag && story.type"
                    class="m-b-m"
                    data-testid="EmbeddedStory_Type"
                  >
                    <div class="StoryFull__type" title="Story Type">
                      {{ story.type }}
                    </div>
                  </div>

                  <div
                    v-if="!storySettings.dateStamp"
                    class="columns is-vcentered is-marginless is-gapless"
                    data-testid="EmbeddedStory_DateStamp"
                  >
                    <div class="column is-narrow">
                      <time
                        v-if="isPublished"
                        :datetime="story.published_at"
                        title="Published On"
                        class="StoryFull__publish-date"
                      >
                        {{ datetime(story.published_at, 'DD MMMM YYYY - HH:mm') }}
                      </time>
                      <span
                        v-else
                        class="story-full__publish-date"
                      >
                        {{ $t('pages.story.draft') }}
                      </span>
                    </div>
                  </div>

                  <v-separator />
                </div>

                <div class="StoryFull__headlines-wrap">
                  <h1 class="StoryFull__headline">
                    {{ story.headline }}
                  </h1>

                  <p
                    v-if="story.subheadline"
                    class="StoryFull__subheadline"
                    v-html="story.subheadline"
                  />
                </div>

                <div
                  class="StoryFull__copy-text content m-b-xl"
                  v-html="story.copytext"
                />

                <div class="StoryFull__boilerplate" v-html="story.boilerplate" />
                <v-separator />
                <div class="StoryFull__contact" v-html="story.contact" />

                <template v-if="!storySettings.contactCards && contacts.length">
                  <v-separator fat darker />

                  <div
                    class="StoryFull__contacts"
                    data-testid="EmbeddedStory_ContactCards"
                  >
                    <div class="title-wrap">
                      <h4 class="title is-4 has-text-weight-semibold">
                        {{ $t('pages.story.contacts') }} /
                      </h4>
                      <div class="subtitle is-5">
                        {{ $t('pages.story.for_more_info') }}
                      </div>
                    </div>

                    <div class="columns">
                      <div
                        v-for="contact in contacts"
                        :key="contact.id"
                        class="column is-4 is-flex"
                      >
                        <contact-card :contact="contact" />
                      </div>
                    </div>
                  </div>
                </template>

                <template v-if="!storySettings.attachments && hasAttachments">
                  <v-separator />

                  <div
                    class="StoryFull__attachments attachments"
                    data-testid="EmbeddedStory_Attachments"
                  >
                    <h4>{{ $t('pages.story.attachments') }}</h4>
                    <Attachments :files="story.files.data" />
                  </div>
                </template>
              </div>
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { StoryApiService } from '@hypefactors/shared/js/services/api/StoryApiService'
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'

import ContactCard from '@/components/core/ContactCard'

export default {
  components: {
    ContactCard
  },

  mixins: [DatetimeMixin],

  props: {
    storySlug: {
      type: String,
      required: true
    },
    newsroomSlug: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      story: null,
      newsroom: null,

      fetchingStory: true
    }
  },

  computed: {
    ...mapGetters([
      'storySettings'
    ]),

    hasAttachments () {
      let attachments = this.story.files

      return attachments && attachments.data.length
    },

    storyCroppedCover () {
      return this.largeImage || this.croppedImagePath
    },

    largeImage () {
      return this.$safeGet(this.story, 'cover.scaled.large.path', null)
    },

    croppedImagePath () {
      return this.$safeGet(this.story, 'cover.cropped', null)
    },

    contacts () {
      return this.story.contacts.data || []
    },

    backRouteConfig () {
      return {
        name: 'embedded.newsroom.stories',
        params: {
          newsroomSlug: this.newsroomSlug
        }
      }
    },

    isPublished () {
      return this.story.published_at
    }
  },

  mounted () {
    this.fetchStory(this.storySlug)
  },

  methods: {
    fetchStory (slug) {
      this.fetchingStory = true

      StoryApiService.fetchStory(slug, {
        params: {
          include: [
            'newsroom.country',
            'files',
            'contacts'
          ]
        }
      })
        .then((storyResponse) => {
          this.story = storyResponse
          this.newsroom = this.story.newsroom.data

          document.body.scrollIntoView()

          this.fetchingStory = false
        })
        .catch((error) => {
          this.$handleError(error)
          this.$router.push(this.backRouteConfig)
        })
    }
  }
}
</script>
